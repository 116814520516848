<template>
  <div>
    

    <CRow>
            <SltAmphur/>
    </CRow>

  </div>
</template>

<script>

import CardScoringForm from "./components/CardScoringForm";
import CardMakeAppointment from "./components/CardMakAppointment";
import WidgetWorkFromHome from "./widgets/WidgetWorkFromHome";
import WidgetFlag from "./widgets/WidgetFlag.vue";
import TblScreeningLogBySchool from "./components/TblScreeningLogBySchool";
import SltAmphur from "./components/SltAmphur";


export default {
  name: "Dashboard",
  components: {
    CardScoringForm,
    CardMakeAppointment,
    WidgetWorkFromHome,
    WidgetFlag,
    TblScreeningLogBySchool,
    SltAmphur
  },
  data() {
    return {
      selected: "Month",
      userData: this.$cookies.get("user"),
    };
  },
  methods: {},
  mounted() {
    //console.log(this.userData);
    
  },
};
</script>
